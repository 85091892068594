@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');


.nav {
    font-family: 'Wix Madefor Display', sans-serif;
    margin-top: 20px;
    color: aliceblue;
    position: absolute;
    width: 100%;
    top:0;
    display: flex;
    justify-content: center;
    gap: 250px;
    font-size: 20px;
    z-index: 1;
}

@media screen and (max-width: 1000px) {
    .nav {
        gap: 50px;
    }
  }

.nav div{
    cursor: pointer;
    height: 100px;
}