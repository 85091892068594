.parallax {
    /* The image used */
    background-image: url("https://i.imgur.com/Bs0AxGj.png");
  
    /* Set a specific height */
    min-height: 500px;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }