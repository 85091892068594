@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

body, html{
    margin: 0;
}

.wrapper1{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Wix Madefor Display', sans-serif;
}

.wrapper2{
    max-width: 1000px;
    width: auto;
}
.textWrapper{
    width: 100%;
}

.imgWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.imgWrapper img{
    width: 45%;
}

.soldOut{
    width: 100%;
}



.title{
    color: rgb(70, 70, 70);
    font-size: 50px;
    margin-bottom: 30px;
}