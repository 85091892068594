@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

.parallax {
    /* The image used */
    background-image: url("https://i.imgur.com/Bs0AxGj.png");
  
    /* Set a specific height */
    min-height: 100vh;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

  }

  .wrapper1{
    max-width: 800px;
    width: auto;
    font-size: 20px;
    color: rgb(40, 40, 40);
    font-family: 'Wix Madefor Display', sans-serif;
  }
  .logo{
    width: 100%;
  }

  .topic{
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
  }