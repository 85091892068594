.bg{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 100vh;
}

.bg video{
  width: 100%
}