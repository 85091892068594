@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@600&display=swap');

body, html{
    margin: 0;
}

.mainWrapper{
    width: auto;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
}