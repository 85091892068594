.main {
    width: 100%;
    height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    z-index: -999;
    
}

.main video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: -999;
}