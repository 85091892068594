.wrapper1{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Wix Madefor Display', sans-serif;
}

.wrapper2{
    margin-top: 100px;
    display: flex;
    width: 1000px;
}
@media screen and (max-width: 1000px) {
    .wrapper2 {
      flex-direction: column;
      width: 100%;
    }
}

.imgWrapper{
    display: flex;
    margin-bottom: 70px;
}

/* @media screen and (max-width: 1000px) {
    .imgWrapper {
      flex-direction: column;
      width: 100%;
    }
} */

.imgWrapper img{
    max-width: 300px;
    width: 100%
}

.title{
    color: rgb(70, 70, 70);
    font-size: 50px;
    margin-bottom: 30px;
}

.paragraph{
    color: rgb(70, 70, 70);
    font-size: 20px;
    margin-bottom: 70px;
    margin-right: 50px;
}

.titleInParagraph{
    font-size: 30px;
}