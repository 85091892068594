.ParallaxVideo{
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0.6);
  z-index: -999;
  
}

.ParallaxVideo video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: -999;
}

.ParallaxVideo > img{
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  z-index: -999;
}


.content {
  margin-top: 20px;
  color: aliceblue;
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  display: flex;
  justify-content: center;
  gap: 250px;
  font-size: 20px;
}

@media screen and (max-width: 1000px) {
  .content {
      gap: 50px;
  }
}

.content > .link{
  cursor: pointer;
}

.link{
  color: aliceblue;
  text-decoration:none

}

.link:hover{
  color: antiquewhite;
}