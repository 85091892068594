@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

body, html{
    margin: 0;
}

.wrapper1{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Wix Madefor Display', sans-serif;
}

.wrapper1 img{
    max-width: 800px;
    width: 100%;
}

.wrapper2{
    margin-top: 100px;
    max-width: 1000px;
    width: auto;
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 1000px) {
  .wrapper2 {
    flex-direction: column;
  }
}


.wrapper3{
    display: flex;
}

@media screen and (max-width: 1000px) {
    .wrapper3 {
      flex-direction: column;
    }
  }

.wrapper3 > img{
    width: 500px;
    margin-right: 30px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
    .wrapper3 > img{
        width: 100%;
        margin-right: 0px;
    }
  }

.title{
    color: rgb(255, 255, 255);
    font-size: 50px;
    margin-bottom: 30px;
}

.paragraph{
    color: rgb(255, 255, 255);
    font-size: 20px;
    margin-bottom: 50px;
}

.point{
    color: rgb(255, 255, 255);
    font-size: 20px;
    margin-bottom: 20px;
}

.point span:first-child{
    color:rgb(183, 251, 255)
}

.theme{
    color: rgb(255, 255, 255);
}

.paragraph a{
    color: aliceblue;
    font-size: 16px;
}