@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

body, html{
    margin: 0;
}

.wrapper1{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Wix Madefor Display', sans-serif;
}

.wrapper1 img{
    max-width: 800px;
    width: 100%;
}

.wrapper2{
    margin-top: 100px;
    max-width: 1000px;
    width: auto;
    display: flex;
}
@media screen and (max-width: 1000px) {
  .wrapper2 {
    flex-direction: column;
  }
}

.wrapper2 > div{
    max-width: 500px;
    width: auto;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: flex-start;
}

.title{
    color: rgb(70, 70, 70);
    font-size: 50px;
    margin-bottom: 30px;
}

.paragraph{
    color: rgb(70, 70, 70);
    font-size: 20px;
    margin-bottom: 70px;
}