@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

body, html{
    margin: 0;
}

.wrapper1{
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    /* grid-column: 3; */
    justify-content: center;
    align-items: center;
    font-family: 'Wix Madefor Display', sans-serif;
    text-align: center;
}

.sponsorTitle{
    margin-top: 100px;
    font-size: 30px;
    color: rgb(45, 45, 45);
}

.logo{
    margin: 20px;
    width: 200px;
}