@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

.wrapper1{
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Wix Madefor Display', sans-serif;

}

.wrapper1 img{
    width: 300px;
}

@media screen and (max-width: 1000px) {
    .wrapper1 img{
        width: 200px;
    }
  }

.wrapper1 a{
    text-decoration: none;
}

.wrapper1 video{
    width: 100vw;
}

.videoWrapper{
    background-color: #000000;
    height: 100vh;
}

         
.wrapper1 button{
    font-size: 22px;
    background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%);
    margin: 10px;
    margin-top: 20px;
    margin-bottom:50px; 
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    border-radius: 10px;
    display: block;
    border: none;
    font-family: 'Wix Madefor Display', sans-serif;
  }

  .wrapper1 button:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
 