@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

body, html{
    margin: 0;
}

.wrapper1{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Wix Madefor Display', sans-serif;
}

.wrapper2{
    max-width: 1000px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 1000px) {
  .wrapper2 {
    flex-direction: column;
  }
}

.wrapper2 img{
    width: 50%;
}

@media screen and (max-width: 1000px) {
    .wrapper2 img {
      width: 100vw;
    }
  }


.wrapper3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.wrapper3 video{
    width: 100%;
    margin-top: 20px;
    border-radius: 50px;
}

.imgWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .imgWrapper {
      flex-direction: column;
    }
  }

.title{
    color: rgb(70, 70, 70);
    font-size: 50px;
}

.paragraph{
    color: rgb(70, 70, 70);
    font-size: 20px;
}

.both{
    opacity: 0.9;
}

.logo{
    opacity: 0.6;
}