@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

body,
html {
  margin: 0;
}

.wrapper1 {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  font-family: 'Wix Madefor Display', sans-serif;

}

.wrapper2 {
  max-width: 1000px;
  width: auto;
}

.imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .imgWrapper {
    flex-direction: column;
  }
}


.ceo {
  margin-right: 30px;
}

.banner {
  width: 100%;
}

.stats {
  width: 100%;
}



.title {
  color: rgb(70, 70, 70);
  font-size: 50px;
  margin-bottom: 30px;
}