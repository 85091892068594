@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@600&display=swap');

body, html{
    margin: 0;
}

.mainWrapper{
    /* max-width: 100%;
    width:100%; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat Alternates', sans-serif;

}

.logo{
    margin-top: 240px;
    margin-right: 20px;
    max-width: 600px;
    width: 70%;
}

.text{
    color: rgb(227, 227, 227);
    margin-bottom: 20px;
}

.inputWrapper{
    text-align: center;
    max-width: 400px;
    width: 80%;
    margin-top: 100px;
}

.mainWrapper input{
    font-family: 'Montserrat Alternates', sans-serif;
    width: 100%;
    height: 60px;
    padding-left: 20px;
    box-sizing: border-box;
    font-size: 18px;
    color: rgb(176, 176, 176);
    background-color: rgb(46,46,46);
    border: none;
    border-radius: 10px;
    outline: none;
    margin-bottom: 20px;
    margin-top: 20px;
}


.mainWrapper button{
    font-family: 'Montserrat Alternates', sans-serif;
    width: 100%;
    height: 60px;
    font-size: 18px;
    color: rgb(255, 255, 255);
    background-color: rgb(85,29,204);
    border: none;
    border-radius: 10px;
    outline: none;
    margin-bottom: 10px;
}

.enableConfirm{
    cursor: pointer;
}

::selection {
    color: rgb(25, 25, 25);
    background:  rgb(196,150,250);
}

.alert{
    color: rgb(25, 25, 25);
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
}

.thankyou{
    font-family: 'Montserrat Alternates', sans-serif;
    color: rgb(62, 62, 62);
    font-size: 18px;
    text-align: center;
}