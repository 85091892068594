@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

body, html{
    margin: 0;
}

.wrapper1{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Wix Madefor Display', sans-serif;
}

.wrapper2{
    max-width: 1000px;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.talent{
    width: 100%;
}

.statsWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .statsWrapper {
      flex-direction: column;
      width: 100%;
    }
}

.building{
    width: 30%;
}

@media screen and (max-width: 1000px) {
    .building {
        width: 100%;
    }
}

.numbers{
    width: 90%
}

@media screen and (max-width: 1000px) {
    .numbers {
        width: 100%;
    }
}

.title{
    color: rgb(70, 70, 70);
    font-size: 50px;
    margin-bottom: 30px;
}