@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

body, html{
    margin: 0;
}

.wrapper1{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Wix Madefor Display', sans-serif;
}

.wrapper2{
    max-width: 1000px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 1000px) {
  .wrapper2 {
    flex-direction: column;
  }
}

.wrapper2 img{
    max-width: 600px;
    width: auto;
}

.wrapper2 video{
    transform: rotate(90deg);
    width: 500px;
}

.title{
    color: rgb(70, 70, 70);
    font-size: 50px;
}

.paragraph{
    color: rgb(70, 70, 70);
    font-size: 20px;
}