@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

.ParallaxVideo{
  font-family: 'Wix Madefor Display', sans-serif;
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0.6);
  z-index: -999;
}

.ParallaxVideo video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -999;
}

.ParallaxVideo > img{
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  z-index: -999;
}

.content {
  margin-top: 20px;
  color: aliceblue;
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  display: flex;
  justify-content: center;
  gap: 250px;
  font-size: 20px;
}

@media screen and (max-width: 1000px) {
  .content {
      gap: 50px;
  }
}

.content > .link{
  cursor: pointer;
}

.link{
  color: aliceblue;
  text-decoration:none

}

.link:hover{
  color: antiquewhite;
}

.wrapper2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.artistWrapper{
  color: aliceblue;
  display: flex;
  margin-top: 100px;
  padding:20px;
  max-width: 1000px;
  width: auto;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .artistWrapper {
      flex-direction: column;
      margin-top: 40px;
  }
}

.artistWrapper > img{
  max-width: 500px;
  margin-right: 50px;
  border-radius: 30px;
}

@media screen and (max-width: 1000px) {
  .artistWrapper > img{
    margin-right: 0px;
    width: 90vw;
  }
}

.artistTextWrapper > .title{
  font-size: 33px;
  margin-bottom: 30px;
}