@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

.wrapper1{
    margin-top: 50px;
    font-family: 'Wix Madefor Display', sans-serif;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title{
    color: rgb(70, 70, 70);
    font-size: 50px;
}

.wrapper2{
    max-width: 1000px;
    width: auto;
}

.imgWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgWrapper img{
    height: 330px
}

@media screen and (max-width: 1000px) { 
    .imgWrapper { 
        flex-direction: column;
    } 
}

@media screen and (max-width: 1000px) { 
    .imgWrapper img{ 
        width: 100%;
        height: auto;
    } 
}