.wrapper1 {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper2 {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.wrapper2 img {
    width: 100%;
}

.wrapper3 img {
    width: 200px;
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 1000px) {
    .wrapper3 img {
        width: 150px;
    }
}