@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');

body,
html {
  margin: 0;
}

.wrapper1 {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Wix Madefor Display', sans-serif;
}

.wrapper1 img {
  max-width: 800px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 30px;
}

.wrapper1 a {
  color: aliceblue;
  text-decoration: none;
}

.wrapper2 {
  margin-top: 100px;
  max-width: 1000px;
  width: auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1000px) {
  .wrapper2 {
    flex-direction: column;
  }
}


.wrapper3 {
  display: flex;
}

@media screen and (max-width: 1000px) {
  .wrapper3 {
    flex-direction: column;
  }
}

.wrapper3>img {
  width: 500px;
  margin-right: 30px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
  .wrapper3>img {
    width: 100%;
    margin-right: 0px;
  }
}

.title {
  color: rgb(255, 255, 255);
  font-size: 50px;
  margin-bottom: 30px;
}

.paragraph {
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin-bottom: 50px;
}

.point {
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin-bottom: 20px;
}

.double {
  display: flex;
}

.double>img {
  width: 50%;
}

.qrcodeWrapper {
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
}


@media screen and (max-width: 1000px) {
  .qrcodeWrapper {
    flex-direction: column;
  }
}

.qrcodeWrapper img {
  max-width: 300px;
  margin: 30px;
}

.link {
  margin-top: 100px;
  margin-bottom: 100px;
  color: white;
  text-align: center;
}

.purple {
  color: rgb(227, 198, 255);
  font-size: 20px;
  margin-bottom: 50px;
}

.purpleBold {
  font-weight: bold;
  color: rgb(227, 198, 255);
}

.orange {
  color: rgb(255, 221, 166);
  font-size: 20px;
  margin-bottom: 50px;
}

.greenBig {
  font-size: 22px;
  color: aquamarine;
}

.qrcode {
  margin-bottom: 70px;
}

.imgWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}